// Custom.scss

// Import icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$primary:       #4e888f;
$secondary:     #404040;
$info:          #00a9c3;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$success:       $green-300;
$warning:       $yellow-300;
$danger:        $red-300;
$light:         #eefcfe;
$dark:          #324b4e;

$body-color:                $gray-900;
$body-bg:                   $gray-300;;

$body-secondary-color:      rgba($body-color, .75);
$body-secondary-bg:         $gray-200;

$body-tertiary-color:       rgba($body-color, .5);
$body-tertiary-bg:          $gray-100;

$body-emphasis-color:       $black;

$card-cap-bg:       $primary;
$card-cap-color:    $light;
$card-color:        $body-secondary-color;
$card-bg:           $body-secondary-bg;

$table-color:   $body-secondary-color;
$table-bg:      $body-secondary-bg;

$modal-content-color:   $body-color;
$modal-content-bg:      $body-bg;
$modal-inner-padding:   5px;

// 4. Include any optional Bootstrap components as you like
// @import "../../node_modules/bootstrap/scss/images";
// @import "../../node_modules/bootstrap/scss/containers";
// @import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/bootstrap";

// 5. Add additional custom code here


// Navbar
//**************************

.navbar-text {
    padding-top: 0px;
    padding-bottom: 0px;
}

.nav-item {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

// Card
//**************************
.card {
    // margin-top: 5px;
    // margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.card-header {
    font-size: 0.85rem;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
}

.card-body {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;;
}

// Alert
//**************************
.alert {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

// Modal
//**************************

.modal-footer {
    display: inline;
}

//*********************************************
// Custom Classes
//*********************************************

.static-data-menu {
    padding-top: 20px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: $secondary;
}

.icon-60 { 
    font-size: 0.6rem;
    vertical-align: middle;
}

.TopMenu-table {
    font-size: 0.7rem;
    margin-bottom: 0px;
    color: var(--bs-navbar-light-color) !important;
    background-color: var(--bs-secondary) !important;
}

.main-frame {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.combo-finder-selling-options-menu {
    margin-top: 5px;
    margin-bottom: 5px;
}

.positions-details-table {
    font-size: 0.75rem;
    text-align: left;
    vertical-align: middle;
}

.positions-details-table-no-padding {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    vertical-align: middle;
}

.special-table-column-width {
    width: 150px;
}

// .Modal-table {
//     margin-bottom: 0px;
//     color: var(--bs-navbar-light-color) !important;
//     background-color: $light !important;
//     border-radius: 0.25rem;
// }

// Button size : super small
.btn-ssm {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.35rem;
    padding-right: 0.35rem;
    border-radius: 0.25rem;
}

.OptionsComboFinder-results-table {
    font-size: 0.8rem;
}

.OptionsComboFinder-results-table-button {
    font-size: 0.8rem;
}


//*********************************************
// Custom IDs
//*********************************************


#Pending-Trade {
    background: $info-bg-subtle;
}

#Simulation-Trade {
    background: $secondary-bg-subtle;
}

#Login-Card {
    width: 600px;
}


//*********************************************
// Grid Classes
//*********************************************


.row {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.row-nomargin-nopadding {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.col {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-1 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-2 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-3 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-4 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-5 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-6 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-7 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-8 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-9 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.col-lg-10 {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
